<template>
  <div class="citypay-container bj">
    <div class="title">
      <div style="height: 50px">
        <el-button
          type="primary"
          icon="el-icon-refresh"
          class="bjsearch"
          @click="Refresh"
        ></el-button>
      </div>
      <div class="seach">
        <div class="times">
          <el-date-picker
            v-model="value"
            @change="createChange"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="申请开始日期"
            end-placeholder="申请结束日期"
          >
          </el-date-picker>
        </div>
        <div class="times">
          <el-date-picker
            v-model="value1"
            @change="countChange"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="打款开始日期"
            end-placeholder="打款结束日期"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select
            filterable
            v-model="from1.logistics_id"
            clearable
            placeholder="选择城市仓"
          >
            <el-option
              v-for="item in storelist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.withdraw_type"
            clearable
            placeholder="选择提现类型"
          >
            <el-option
              v-for="item in withdrawlist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.admin_id"
            clearable
            placeholder="选择财务员"
          >
            <el-option
              v-for="item in userlist"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-input
            v-model="from1.username"
            placeholder="输入持卡人姓名"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.logistic_business_id"
            clearable
            placeholder="请选择集配中心"
          >
            <el-option
              v-for="item of logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="from1.status"
            clearable
            placeholder="请选择打款状态"
          >
            <el-option label="全部打款状态" value=""></el-option>
            <el-option label="申请中" value="1"></el-option>
            <el-option label="已打款" value="2"></el-option>
            <el-option label="已拒绝" value="3"></el-option>
            <el-option label="打款退回" value="4"></el-option>
            <el-option label="打款中" value="5"></el-option>
          </el-select>
        </div>
        <div class="inputs" style="width: auto">
          <el-button @click="search" type="primary" icon="el-icon-search"
            >查询</el-button
          >
        </div>
        <div class="inputs" style="width: auto">
          <el-button
            :disabled="!tableData.length || disabledExport"
            @click="onHandleExport"
            type="warning"
            icon="el-icon-download"
            >导出</el-button
          >
        </div>
        <div class="inputs" style="width: auto">
          <el-button
            :disabled="!batchIds.length || disabledBd"
            @click="postAjaxStoreLogisticsBdBankBatchPay"
            type="danger"
            >保定银行批量打款</el-button
          >
        </div>
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        ref="multipleTable"
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          :selectable="checkSelection"
        >
        </el-table-column>
        <el-table-column type="index" align="center" width="80" label="序号">
        </el-table-column>
        <el-table-column prop="withdraw_no" align="center" label="打款单号">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.withdraw_no) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="logistic_business_name"
          align="center"
          label="集配中心"
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.logistic_business_name) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="title"
          align="center"
          label="城市仓名称"
          min-width="120"
        >
          <template slot-scope="scope">
            <!-- scope.row.logistics_name -->
            <div>
              <div>{{ scope.row.logistics_name || "-" }}</div>
              <div
                class="withdrawal_tag"
                v-if="scope.row.is_withdrawal === IS_WITHDRAWAL.no.value"
              >
                {{ `【${IS_WITHDRAWAL.no.label}】` }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="bank_username" align="center" label="持卡人姓名">
          <template slot-scope="scope">
            {{ scope.row?.withdraw_bank?.username || "——" }}
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="持卡人账号">
          <template slot-scope="scope">
            <span>{{ $empty.empty(scope.row?.withdraw_bank?.card_code) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="申请时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="withdraw_type" align="center" label="提现类型">
          <template slot-scope="scope">
            <span v-if="scope.row.withdraw_type == 1">运费提现</span>
            <span v-else>服务费提现</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="可提现总金额(元)">
          <template slot-scope="scope">
            {{
              tool.toDecimal2((scope.row.avail_amount * 10000) / 10000 / 10000)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="money" align="center" label="申请提现金额(元)">
          <template slot-scope="scope">
            {{ tool.toDecimal2((scope.row.money * 10000) / 10000 / 10000) }}
          </template>
        </el-table-column>
        <el-table-column prop="reject_time" align="center" label="拒绝时间">
          <template slot-scope="scope">
            {{ scope.row.reject_time || "—" }}
          </template>
        </el-table-column>
        <el-table-column prop="backoff_time" align="center" label="退回时间">
          <template slot-scope="scope">
            {{ scope.row.backoff_time || "—" }}
          </template>
        </el-table-column>
        <el-table-column prop="status_name" align="center" label="打款状态">
        </el-table-column>
        <el-table-column
          prop="operate_type_name"
          align="center"
          label="是否银企直联打款"
        >
        </el-table-column>

        <el-table-column prop="transfertime" align="center" label="打款日期">
          <template slot-scope="scope">
            {{ $empty.time(scope.row.transfertime) }}
          </template>
        </el-table-column>
        <el-table-column prop="admin" align="center" label="财务专员">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.admin_name) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <div class="d-flex flex-column a-center j-center">
              <el-button
                type="text"
                class="main-color pointer text-none"
                @click="getinfo(scope.row)"
                >查看详情</el-button
              >
              <div v-if="scope.row.status == 1" class="d-flex j-sa">
                <el-button
                  type="text"
                  class="main-color pointer text-none"
                  v-if="scope.row.is_withdrawal === IS_WITHDRAWAL.yes.value"
                  @click="
                    id = scope.row.id;
                    payType = 2;
                    dialogFormVisible = true;
                  "
                  >手动打款完成</el-button
                >
                <el-button
                  style="color: #e6a23c"
                  type="text"
                  class="pointer text-none"
                  @click="
                    id = scope.row.id;
                    payType = 3;
                    dialogFormVisible = true;
                  "
                  >拒绝打款</el-button
                >
              </div>
              <el-button
                type="text"
                class="main-color pointer text-none"
                v-if="scope.row.status == 2"
                @click="
                  id = scope.row.id;
                  payType = 4;
                  dialogFormVisible = true;
                "
                >打款退回</el-button
              >
            </div>
            <!-- <el-button type="text" class="main-color pointer text-none" v-if="scope.row.status == 2"
                @click="getinfo(scope.row,'back')">打款退回</el-button>
              <el-button type="text" class="main-color pointer text-none" v-if="scope.row.status == 1"
                @click="getinfo(scope.row,'refuse')">拒绝</el-button> -->
            <!-- <router-link
                :to="{
                  name: 'payDetail',
                  query: { id: scope.row.id, status: scope.row.status },
                }"
                class="main-color pointer text-none"
                >查看详情
              </router-link>
              <router-link
                v-if="scope.row.status == 1"
                :to="{
                  name: 'payDetail',
                  query: { id: scope.row.id, status: scope.row.status },
                }"
                class="main-color pointer text-none"
                >打款
              </router-link> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  审核拒绝弹出框  -->
    <el-dialog
      :title="payType == 2 ? '打款' : '拒绝打款'"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
      width="550px"
      class="dialog"
      center
    >
      <div class="contents1">
        <template v-if="payType == 2">
          <el-upload
            action="#"
            :on-preview="handlePictureCardPreview"
            :class="{ hide: imageUrl.length == 1 || !imageUrl }"
            :file-list="imageUrl"
            list-type="picture-card"
            :http-request="uploadFile"
            :before-remove="deleteFile"
            :limit="1"
          >
            <i slot="trigger" class="el-icon-plus"></i>
          </el-upload>
        </template>
        <template v-if="payType > 2">
          <el-form
            :model="auditFrom"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="" prop="refuseReason">
              <el-input
                type="textarea"
                v-model="auditFrom.refuseReason"
                :autosize="{ minRows: 10 }"
                placeholder="填写拒绝打款原因"
              ></el-input>
            </el-form-item>
          </el-form>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            auditFrom.refuseReason = '';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="submitFrom">确认</el-button>
      </span>
    </el-dialog>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="from1.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
// import { BASE } from "@/api";
import { Loading } from "element-ui";
import url from "url";
import { BASE } from "@/api";
import { postStoreLogisticsBdBankBatchPay } from "@/api/general/index.js";
// import { getOffsetDate } from "@/utils/tools/date.js";
import { getCityExport } from "@/api/export/index";
import { downLoadFlowBlob } from "@/utils/tools/base";
// import { postLogisticsWithdrawExport } from "@/api/export/center";
import { IS_WITHDRAWAL } from "./utils/enum/index";

export default {
  name: "cityPayment",
  components: {},
  data() {
    return {
      batchIds: [], // 批量打款ids
      disabledBd: false, // 批量打款按钮
      //列表新建页面初始参数
      loading: false,
      dialogFormVisible: false,
      IS_WITHDRAWAL,
      imageUrl: [],
      payType: "",
      payment_img: "",
      id: "",
      from: {
        withdraw_type: "",
        username: "",
        apply_time_start: "",
        apply_time_end: "",
        remit_time_start: "",
        remit_time_end: "",
        logistics_id: "",
        admin_id: "",
        status: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "", //集配中心id
      },
      from1: {
        withdraw_type: "",
        username: "",
        apply_time_start: "",
        apply_time_end: "",
        remit_time_start: "",
        remit_time_end: "",
        logistics_id: "",
        admin_id: "",
        status: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "", //集配中心id
      },
      auditFrom: {
        wantToBuyId: 0,
        auditState: 3,
        refuseReason: "",
      },
      withdrawlist: [
        {
          id: "1",
          name: "运费提现",
        },
        {
          id: "2",
          name: "服务费提现",
        },
      ],
      tableData: [],
      userlist: [],
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      ruleForm: {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        itemdata: "",
        name: "",
        username: "",
        password: "",
      },
      authentication: [
        {
          id: -1,
          name: "全部",
        },
        {
          id: 0,
          name: "未认证",
        },
        {
          id: 1,
          name: "审核中",
        },
        {
          id: 2,
          name: "审核成功",
        },
        {
          id: 3,
          name: "审核失败",
        },
      ],
      proName: {
        2: "确定打款吗？",
        3: "确定拒绝打款吗？",
        4: "确定打款退回吗？",
      },
      backName: {
        2: "已打款",
        3: "已拒绝",
        4: "已退回",
      },
      title: "",
      centerDialogVisible: false,
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        business_type_code: [
          { required: true, message: "请选择业务模块", trigger: "change" },
        ],
        itemdata: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
      },
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      value: "",
      value1: "",
      options1: [],
      storelist: [],
      list: [],
      list1: [],
      logisticsList: [], // 集配中心列表
      disabledExport: false, // 导出禁用
    };
  },
  created() {
    if (window.location.href.indexOf("?") > -1) {
      let url = this.tool.getUrl(window.location.href);
      this.from = url;
      this.from1 = url;
      this.value = this.from1.apply_time_start
        ? [this.from1.apply_time_start, this.from1.apply_time_end]
        : "";
      this.value1 = this.from1.remit_time_start
        ? [this.from1.remit_time_start, this.from1.remit_time_end]
        : "";
      this.currentPage = Number(this.from1.page);
      this.from.pageSize = Number(this.from1.pageSize);
      this.from.admin_id = this.from.admin_id ? Number(this.from.admin_id) : "";
      this.from.logistics_id = this.from.logistics_id
        ? Number(this.from.logistics_id)
        : "";
      this.from1.admin_id = this.from1.admin_id
        ? Number(this.from1.admin_id)
        : "";
      this.from1.logistics_id = this.from1.logistics_id
        ? Number(this.from1.logistics_id)
        : "";
      this.from.logistic_business_id = this.from.logistic_business_id
        ? Number(this.from.logistic_business_id)
        : "";
      this.from1.logistic_business_id = this.from1.logistic_business_id
        ? Number(this.from1.logistic_business_id)
        : "";
    }
    this.hqlist();
    this.citylist();
    this.getUser();
    this.getAjaxLogisticsList();
    //   this.seletlist();
  },
  methods: {
    // 禁止提现的城市仓不可点击
    checkSelection(row) {
      let arr = [1, 6, 7]; // 1,,6,7 可以点击批量银企打款
      return (
        row.is_withdrawal != this.IS_WITHDRAWAL.no.value &&
        arr.includes(row.status)
      );
    },
    // 批量选择
    handleSelectionChange(val) {
      this.batchIds = [];
      if (val.length > 0) {
        val.map((vItem) => {
          this.batchIds.push(vItem.id);
        });
      }
      console.log(this.batchIds, "dddd-");
    },
    // 批量打款
    async postAjaxStoreLogisticsBdBankBatchPay() {
      this.disabledBd = true;
      try {
        const res = await postStoreLogisticsBdBankBatchPay({
          withdraw_ids: this.batchIds,
        });
        console.log(res, "res===");
      } catch (error) {
        console.log(error, "postStoreLogisticsBdBankBatchPay");
      } finally {
        this.$refs.multipleTable.clearSelection();
        this.batchIds = [];
        this.Refresh();
        this.disabledBd = false;
      }
    },
    /**
     * 获取集配中心列表
     */
    async getAjaxLogisticsList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticsList = data;
        console.log("ajax getAjaxLogisticsList", data);
      } catch (err) {
        console.log("ajax getAjaxLogisticsList err", err);
      }
    },
    getinfo(row, state) {
      this.from.currentPage = this.currentPage;
      this.from.value = this.value;
      this.from.value1 = this.value1;
      this.$router.push({
        path: `/cityPaymentDetail`,
        query: {
          id: row.id,
          status: row.status,
          state: state,
        },
      });
    },
    citylist() {
      this.$api.general
        .logisticsList({
          name: "",
          page: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log(res, "获取数据");
          this.storelist = res.data.data;
        });
    },
    search() {
      this.from.page = 1;
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    /**
     * 导出数据（下载中心）
     */
    async onHandleExport() {
      /** 旧导出逻辑 */
      // this.$message.success({
      //   message: "正在导出，请耐心等待",
      //   duration: 999,
      // });

      const query = { ...this.from1, token: sessionStorage.getItem("token") };
      delete query.page;
      delete query.pageSize;
      const params = url.format({ query });
      const path = BASE.PRO1 + "/logistics/withdraw/list/export";
      window.open(path + params);

      // try {
      //   let params = this.tool.DeepCopy({}, this.from1);
      //   delete params.page;
      //   delete params.pageSize;
      //   const res = await getCityExport(params);
      //   downLoadFlowBlob(res, res?.filename);
      //   this.$message.success("导出成功");
      // } catch (err) {
      //   this.$message.error("导出失败了");
      //   console.log("ajax err", err);
      // } finally {
      //   this.$message.closeAll();
      // }
      /** 新导出逻辑 */
      // this.disabledExport = true;
      // try {
      //   const query = {
      //     ...this.from1,
      //     token: sessionStorage.getItem("token"),
      //   };
      //   delete query.page;
      //   delete query.pageSize;
      //   await postLogisticsWithdrawExport(query);
      //   this.goExportCenter();
      // } catch (err) {
      //   console.log("ajax postLogisticsWithdrawExport err", err);
      //   this.disabledExport = false;
      // }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    uploadFile(options) {
      this.loadImage = Loading.service({ fullscreen: false });
      // 拿到 file
      let file = options.file;
      let date = new Date().getTime();
      console.log(file, "文件");
      var event = event || window.event;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        console.log(e.target.result);
        this.uploadImage(e.target.result);
      };
    },
    updatePaymentImg(img) {
      this.$api.general
        .uploadPaymentImg({
          withdraw_id: this.id,
          payment_img: img,
        })
        .then((res) => {})
        .catch((error) => {});
    },
    uploadImage(img) {
      this.$api.general
        .uploadImage({ file_data: img })
        .then((res) => {
          this.imageUrl = [{ url: res.data }];
          this.payment_img = res.data;
          this.loadImage.close();
          this.updatePaymentImg(this.payment_img);
        })
        .catch((error) => {
          this.imageUrl = [];
          this.loadImage.close();
        });
      console.log(this.imageUrl);
    },
    handlePictureCardPreview(file) {
      this.bigImage = file.url;
      this.showImage = true;
    },
    deleteFile(file, fileList) {
      console.log(file, "删除");
      console.log(fileList, "删除");
      this.imageUrl = fileList;
      this.payment_img = "";
      console.log(this.imageUrl);
      // this.updatePaymentImg(this.merchantList.payment_img)
    },
    createChange(val) {
      console.log(val);
      if (val) {
        this.from1.apply_time_start = val[0];
        this.from1.apply_time_end = val[1];
      } else {
        this.from1.apply_time_start = "";
        this.from1.apply_time_end = "";
        this.value = "";
      }
    },
    countChange(val) {
      console.log(val);
      if (val) {
        this.from1.remit_time_start = val[0];
        this.from1.remit_time_end = val[1];
      } else {
        this.from1.remit_time_start = "";
        this.from1.remit_time_end = "";
        this.value1 = "";
      }
    },
    getUser() {
      this.$api.general.generalUserList().then((res) => {
        console.log(res, "获取数据");
        this.userlist = res.data.data;
      });
    },
    submitFrom() {
      this.$confirm(`${this.proName[this.payType]}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.load = Loading.service({ fullscreen: true });
          this.$api.general
            .withdrawAuditPayment({
              withdraw_id: this.id,
              status: this.payType, //2：通过，3：拒绝
              payment_img: this.payment_img,
              refuse_remarks: this.auditFrom.refuseReason,
            })
            .then((res) => {
              this.load.close();
              this.auditFrom.refuseReason = "";
              this.payment_img = "";
              this.hqlist();
              this.tool.message(`${this.backName[this.payType]}`, "success");
              this.dialogFormVisible = false;
            })
            .catch((error) => {
              this.dialogFormVisible = false;
              this.load.close();
            });
        })
        .catch(() => {});
    },
    Refresh() {
      this.from = {
        withdraw_type: "",
        username: "",
        apply_time_start: "",
        apply_time_end: "",
        remit_time_start: "",
        remit_time_end: "",
        logistics_id: "",
        admin_id: "",
        status: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "",
      };
      this.from1 = {
        withdraw_type: "",
        username: "",
        apply_time_start: "",
        apply_time_end: "",
        remit_time_start: "",
        remit_time_end: "",
        logistics_id: "",
        admin_id: "",
        status: "",
        page: 1,
        pageSize: 10,
        logistic_business_id: "",
      };
      this.value = "";
      this.value1 = "";
      this.currentPage = 1;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.tool.getResult(this.from, window.location.href);
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      this.$api.general.logisticsWithdrawList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss">
.withdrawal_tag {
  color: $danger-color;
}
.citypay-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }

  .contents1 {
    box-sizing: border-box;
    padding: 40px 26px;
    text-align: center;

    .span {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #349ee6;
      padding: 4px 13px 4px 0;
      box-sizing: border-box;
      background: #f5f7fa;
      display: flex;
      align-content: center;
      margin-bottom: 20px;

      .w {
        width: 3px;
        background-color: #349ee6;
        margin-right: 13px;
      }
    }

    .el-form-item__content {
      margin-left: 0px !important;
    }
  }

  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .inputs {
        width: 207px;
        margin-right: 8px;
        margin-bottom: 10px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }
}

.colors {
  color: #fa884c;
}

.times {
  margin-right: 8px;
}

// .el-picker-panel {
//     left: 1245px !important;
// }
</style>
